import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import { StaticQuery, graphql } from 'gatsby'
import { fluidImage, fluidImageWide } from '../fragments'
import Img from 'gatsby-image'
import Helmet from 'react-helmet'
const IndexPage = () => (
  <StaticQuery
    query={graphql`
      query HomeQuery {
        BG: file(relativePath: { eq: "esther-jiao-640128-unsplash.jpg" }) {
          ...fluidImageWide
        }
        AWSCertified: file(
          relativePath: { eq: "Solutions-Architect-Associate.png" }
        ) {
          ...fluidImage
        }
      }
    `}
    render={(data) => (
      <Layout>
        <Helmet>
          <title>
            Tobias Meixner - Software Builder with AWS, GraphQL & Serverless
          </title>
          <meta
            name="description"
            content="Tobias Meixner is Software Builder using AWS, GraphQL & Serverless. Also Co-Founder at Brikl & Hubql"
          />
          <meta
            property="og:title"
            content="Tobias Meixner - Software Builder using AWS, GraphQL & Serverless"
          />
          <meta
            property="og:description"
            content="Tobias Meixner is Software Builder using AWS, GraphQL & Serverless. Also Co-Founder at Brikl & Hubql"
          />
          <meta
            name="twitter:title"
            content="Tobias Meixner - Software Builder using AWS, GraphQL & Serverless"
          />
          <meta
            name="twitter:description"
            content="Tobias Meixner is Software Builder using AWS, GraphQL & Serverless. Also Co-Founder at Brikl & Hubql"
          />
        </Helmet>
        <Img
          fluid={data.BG.childImageSharp.fluid}
          style={{
            zIndex: -1,
            objectFit: 'cover',
            objectPosition: 'center',
            width: '100%',
            height: '100%',
            margin: 0,
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
          }}
          alt="Background"
        />
        <h1 className="mainHeadline">Build software together.</h1>
        <Link
          style={{
            width: '150px',
          }}
          className="button"
          to="/about"
        >
          Learn more
        </Link>
      </Layout>
    )}
  />
)

export default IndexPage
